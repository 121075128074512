<template>
   <router-view v-if="show"></router-view>
</template>

<script>
   import {mapState} from 'vuex';

   export default {
      name: "BaseComponent",
      data() {
         return {
            show: false
         }
      },
      computed: {
         ...mapState([
            'locales',
            'region'
         ])
      },
      created() {
         if (!this.region.currentRegion && this.$route.name != 'regions') {
            if (this.$route.query.region !== undefined) {
               let _this = this;
               _this.$store.dispatch("region/getAll").then(function (result) {
                  result.forEach((region) => {
                     if (region.slug === _this.$route.query.region) {
                        _this.$store.commit('region/setCurrentRegion', region)
                     }
                  })
               });
            } else {
               this.$router.push({
                  name: 'regions', params: {
                     locale: this.$route.params.locale,
                     nextUrl: this.$route.fullPath
                  }
               });
            }
         }

         if (this.$route.params.locale in this.locales.locales) {
            this.$store.commit('locales/setCurrentLocale', this.$route.params.locale);
         } else {
            this.$store.commit('locales/setCurrentLocale', this.$store.state.locales.defaultLocale);
         }

         if (
            this.$route.params.locale &&
            !this.$route.params.pathMatch &&
            !(this.$route.params.locale in this.locales.locales)
         ) {
            this.$router.replace({
               name: '404', params: {
                  locale: this.$route.params.locale ? this.$route.params.locale : null
               }
            });
         }

         this.show = true;
      }
   }
</script>

<style scoped>

</style>
